<template>
  <div
    class="w-1/2 flex flex-col justify-center p-2"
    style="top: 0; left: 0; bottom: 0; right: 0; margin: 5% auto"
  >
    <div class="flex flex-col">
      <span class="text-xl font-black my-3"> Sync to an existing account </span>
      <div class="w-full flex flex-grow">
        <span class="flex flex-grow text-base text-romanSilver"
          >Sign in to an existing acccount</span
        >
      </div>
    </div>

    <ValidationObserver rules="required" v-slot="{ handleSubmit }">
      <div class="flex w-full mt-2">
        <div class="border w-full flex flex-col p-6 bg-ghostWhite rounded-sm">
          <div class="w-full flex flex-col mb-2">
            <c-text
              placeholder="e.g aeme@scelloo.com"
              variant="w-full h-10"
              class="label-input"
              v-model="payload.email"
              label="Email Address"
              :rules="EmailRule"
              type="email"
            />
          </div>

          <div class="w-full flex flex-col mb-2">
            <div class="flex">
            <c-text
              placeholder="e.g aeme**********"
              variant="h-10"
              class="label-input w-full"
              v-model="payload.authPassword"
              label="Password"
              :rules="['required']"
              :type="showPassword ? 'text' : 'password'"
            />
            <Icon
            class-name="text-romanSilver -mt-2 h-full cursor-pointer -ml-8 self-center"
            size="xsm"
            :icon-name="showPassword ? 'eye-open' : 'eye-close'"
            style="z-index: 9"
            @click.native="togglePassword()"
          />
          </div>

          </div>
        </div>
      </div>

      <div class="pl-3 flex w-full justify-end mt-5">
        <div class="flex">
          <Button
            :disabled="disableBtn"
            :class="disableBtn ? `bg-disabled` : `bg-dynamicBackBtn text-white`"
            @click="handleSubmit(syncAccount)"
          >
            <span class="mr-6 ml-6">Sync Account</span>
          </Button>
        </div>
      </div>
    </ValidationObserver>
  </div>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import CText from "@scelloo/cloudenly-ui/src/components/text";
import Button from "@/components/Button";

export default {
  name: "AccountSync",
  components: {
    ValidationObserver,
    Button,
    CText,
  },
  data() {
    return {
      selectMode: true,
      disableBtn: false,
      payload: {
        email: "",
        authPassword: "",
        userId: "",
        accountSync: true,
      },
      EmailRule: [
        "required",
        {
          name: "email",
          rule: (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
        },
      ],
      showPassword: false
    };
  },

  methods: {
    togglePassword() {
      this.showPassword = !this.showPassword;
    },
    syncAccount() {
      this.payload.userId = this.$AuthUser.id;
      this.$_loginAccount(this.payload)
        .then((response) => {
          const jwtToken = response.data.token;
          const linchpin = response.data;

          window.localStorage.setItem("linchpin", JSON.stringify(linchpin));

          window.localStorage.setItem("token", jwtToken);

          const syncedAccount = { state: true };
          window.localStorage.setItem(
            "syncAccount",
            JSON.stringify(syncedAccount)
          );

          window.location.reload();
        })
        .catch(err => {
          this.$toasted.error(err, { duration: 5000 });
        });
    },
  },
};
</script>

<style>

.label-input select {
  background-color: #ffffff;
}
.bg-disabled {
  background-color: #eeeeee;
  border-color: transparent;
}
</style>
